<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card class="v-card-bottom-30">
      <v-card-title class="py-5">
        <h4>{{ use_language.home_page }}</h4> <v-icon>mdi-chevron-right</v-icon><span
          class="font-weight-regular body-1">{{ use_language.report_profit }}</span>
        <v-spacer />
      </v-card-title>
    </v-card>

    <v-card class="v-card-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="py-2 ml-3">{{ use_language.search }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" xl="1">
            <span>{{ use_language.date }} : </span>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu ref="search_menu_date_from" v-model="search_menu_date_from" :close-on-content-click="false"
              :return-value.sync="search_date_from" transition="scale-transition" offset-y max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :value="computed_search_date_from" @change="value => computed_search_date_from = value"
                  autocomplete="off" label="เริ่ม" outlined prepend-inner-icon="mdi-calendar" readonly hide-details
                  dense v-on="on">
                  <template v-slot:label> <span>{{ use_language.start_ }}</span> </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="search_date_from"
                @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title
                :allowed-dates="allowedDates" class="my-0">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu ref="search_menu_date_to" v-model="search_menu_date_to" :close-on-content-click="false"
              :return-value.sync="search_date_to" transition="scale-transition" offset-y max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :value="computed_search_date_to" @change="value => computed_search_date_to = value"
                  autocomplete="off" label="สิ้นสุด" outlined prepend-inner-icon="mdi-calendar" readonly hide-details
                  dense v-on="on">
                  <template v-slot:label> <span>{{ use_language.end }}</span> </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="search_date_to"
                @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title
                :allowed-dates="allowedDates" class="my-0">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn dark color="primary" :loading="loading_btn" @click="search_data_profit()"
              class="mr-3">{{ use_language.search }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row justify="start" align="center" class="my-7 margin-card-status">
      <v-col cols="12" sm="6" md="4" lg="2" align="left" class="px-2">
        <v-card >
          <v-card-title :style="{ fontSize: '16px' }" class="px-2">
            {{ use_language.total_sales }}
          </v-card-title>
          <v-divider />
          <v-card-title   class="px-1">
            <v-row align="center">
              <v-col align="center" class="px-1">
                <h4 class='text-gray'> <span
                    v-if="profitTotal.length != 0">{{ format_number(profitTotal["orderTotalAmount"]) }}</span> <span
                    v-if="profitTotal.length == 0">0</span> ฿</h4> 
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" align="left" class="px-2">
        <v-card >
          <v-card-title :style="{ fontSize: '16px' }" class="px-2">
            {{ use_language.total_productCost }}
          </v-card-title>
          <v-divider />
          <v-card-title   class="px-1">
            <v-row align="center">
              <v-col align="center" class="px-1">
                <h4 class='text-red'> <span
                    v-if="profitTotal.length != 0">{{ format_number(profitTotal["productCostPrice"]) }}</span> <span
                    v-if="profitTotal.length == 0">0</span> ฿</h4> 
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" align="left" class="px-2">
        <v-card  >
          <v-card-title :style="{ fontSize: '16px' }" class="px-2">
            {{ use_language.sale_expense }}
          </v-card-title>
          <v-divider />
          <v-card-title   class="px-1">
            <v-row align="center">
              <v-col align="center" class="px-1">
                <h4 class='text-red'> <span
                    v-if="profitTotal.length != 0">{{ format_number(profitTotal["totalExpenseCost"]) }}</span> <span
                    v-if="profitTotal.length == 0">0</span> ฿</h4> 
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" align="left" class="px-2">
        <v-card >
          <v-card-title :style="{ fontSize: '16px' }" class="px-2">
            {{ use_language.total_profit }}
          </v-card-title>
          <v-divider />
          <v-card-title   class="px-1">
            <v-row align="center">
              <v-col align="center" class="px-1">
                <h4 class='text-green'> <span
                    v-if="profitTotal.length != 0">{{ format_number(profitTotal["profit"]) }}</span>
                  <span v-if="profitTotal.length == 0">0</span> ฿
                </h4> 
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" align="left" class="px-2">
        <v-card  >
          <v-card-title :style="{ fontSize: '16px' }" class="px-2">
            {{ use_language.total_AdministrativeExpenseCost }}
          </v-card-title>
          <v-divider />
          <v-card-title  class="px-1">
            <v-row align="center">
              <v-col align="center" class="px-1">
                <h4 class='text-red'> <span v-if="profitTotal.length != 0">{{ format_number(TotalManagementExpense * -1) }}</span> <span
                    v-if="profitTotal.length == 0">0</span> ฿</h4> 
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" align="left" class="px-2">
        <v-card  >
          <v-card-title :style="{ fontSize: '16px' }" class="px-2">
            {{ use_language.total_profitNet }}
          </v-card-title>
          <v-divider />
          <v-card-title   class="px-1">
            <v-row align="center">
              <v-col align="center" class="px-1">
                <h4 class='text-green' v-if="profitTotal.profit - TotalManagementExpense>=0">  {{ format_number((profitTotal["profit"] - TotalManagementExpense)) }} ฿</h4> 
                <h4 class='text-red' v-else>  {{ format_number((profitTotal["profit"] - TotalManagementExpense)) }} ฿</h4> 
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="v-card-bottom-30">
      <v-card-title>
        <v-icon size="20" color="success">fa-receipt</v-icon><span class="py-2 ml-3">{{ use_language.info_order }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">

        <div class="padding-mobile-display" style="height: 500px; overflow-y: auto;">
          <v-data-table v-if="profitReportTable_list != []" :headers="profitReportTable_headers"
            :items="profitReportTable_list" class="elevation-0 packhai-border-table" hide-default-footer
            :items-per-page="10000" mobile-breakpoint="0">

            <template v-slot:item.id="{ item }">
              <a style="text-decoration: underline" @click="detail_page(item.id)">{{  format_order_id(item.id) }}</a>
             
            </template>
            <template v-slot:item.orderTotalAmount="{ item }">
              <span>{{ format_price(item.orderTotalAmount) }}</span>
            </template>
            <template v-slot:item.createdDatetime="{ item }">
              {{ set_format_date(item.createdDatetime) }}
            </template>
            <template v-slot:item.sendToExpressDateTime="{ item }">
              {{ set_format_date(item.sendToExpressDateTime) }}
            </template>
            <template v-slot:item.productCostPrice="{ item }">
              <span v-show="Number(item.productCostPrice) !== 0"
                :class="item.productCostPrice >= 0 ? 'text-green' : 'text-red'">{{ format_price(item.productCostPrice)
                }}</span>
            </template>
            <template v-slot:item.totalExpenseCost="{ item }">
              <span v-show="Number(item.totalExpenseCost) !== 0"
                :class="format_price(item.totalExpenseCost) >= 0 ? 'text-green' : 'text-red'">{{ format_price(
                  item.totalExpenseCost) }}</span>
            </template>
            <template v-slot:item.profit="{ item }">
              <span :class="item.profit >= 0 ? 'text-green' : 'text-red'">{{ format_price(item.profit) }}</span>
            </template>
            <template v-slot:item.customerName="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                    <span>{{ item.customerName }}</span>
                  </div>

                </template>
                <span>
                  <span>{{ item.customerName }}</span>
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>

      </v-card-text>
    </v-card>

    <v-card class="v-card-bottom-30">
      <v-card-title>
        <v-icon size="20" color="success">fa-money-bill-wave</v-icon><span
          class="py-2 ml-3">{{ use_language.sale_expense }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">

        <div class="padding-mobile-display" style="height: 350px; overflow-y: auto;">
          <v-data-table :headers="expenseReportTable_headers" :items="expenseItems"
            class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000" mobile-breakpoint="0">
            <template v-slot:item.invoiceDate="{ item }">
              {{ set_format_date_time(item.invoiceDate) }}
            </template>
            <template v-slot:item.num="{ item }">
              {{ (expenseItems.indexOf(item)) + 1 }}
            </template>
            <template v-slot:item.idText="{ item }">
              <a style="text-decoration: underline" @click="detail_po(item.idText)">{{ item.idText }}</a>
            </template>
            <template v-slot:item.amountBeforeVat="{ item }">
              {{  format_price(item.amountBeforeVat) }}
            </template>
            <template v-slot:item.remark="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                    <span>{{ item.remark }}</span>
                  </div>

                </template>
                <span>
                  <span>{{ item.remark }}</span>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.referenceNumber="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                    <span>{{ item.referenceNumber }}</span>
                  </div>

                </template>
                <span>
                  <span>{{ item.referenceNumber }}</span>
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>

      </v-card-text>
    </v-card>

    <v-card class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span
          class="ml-3">{{ use_language.proportion_sale_expense }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">
        <v-row justify="start" align="center">
          <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartExpenseTypeSummary.length > 0">
            <GChart type="PieChart" :settings="{ packages:['corechart'] }" :data="chartExpenseTypeSummary"
              :options="chartPieOptions.chart" />
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartTotalSummary.length > 0">
            <div class="padding-mobile-display" style="overflow-y: auto;">
              <v-data-table :headers="piechartexpenseTable_headers" :items="expenseTypeSummary"
                class="elevation-0 packhai-border-table " hide-default-footer :items-per-page="10000"
                mobile-breakpoint="0">
                <template v-slot:item.percentcal="{ item }">
                  {{ get_percent_expense(item.amountBeforeVat) }} %
                </template>
                <template v-slot:item.amountBeforeVat="{ item }">
                  {{ format_price(item.amountBeforeVat) }}
                </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-card class="v-card-bottom-30" v-if="manageExpenseItems.length>0">
      <v-card-title>
        <v-icon size="20" color="success">fa-money-bill-wave</v-icon><span
          class="py-2 ml-3">{{ use_language.administrative_expenses }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">

        <div class="padding-mobile-display" style="height: 350px; overflow-y: auto;">
          <v-data-table :headers="expenseManagementReportTable_headers" :items="manageExpenseItems"
            class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000" mobile-breakpoint="0">
            <template v-slot:item.invoiceDate="{ item }">
              {{ set_format_date_time(item.invoiceDate) }}
            </template>
            <template v-slot:item.num="{ item }">
              {{ (manageExpenseItems.indexOf(item)) + 1 }}
            </template>
            <template v-slot:item.idText="{ item }">
              <a style="text-decoration: underline" @click="detail_po(item.idText)">{{ item.idText }}</a>
            </template>
            <template v-slot:item.amountBeforeVat="{ item }">
              {{  format_price(item.amountBeforeVat) }}
            </template>
            <template v-slot:item.remark="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                    <span>{{ item.remark }}</span>
                  </div>

                </template>
                <span>
                  <span>{{ item.remark }}</span>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.referenceNumber="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                    <span>{{ item.referenceNumber }}</span>
                  </div>

                </template>
                <span>
                  <span>{{ item.referenceNumber }}</span>
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>

      </v-card-text>
    </v-card>

    <v-card class="v-card-bottom-30" v-if="manageExpenseTypeSummary.length>0">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span
          class="ml-3">{{ use_language.proportion_of_manage_expense }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">
        <v-row justify="start" align="center">
          <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartManageExpenseTypeSummary.length > 0">
            <GChart type="PieChart" :settings="{ packages:['corechart'] }" :data="chartManageExpenseTypeSummary"
              :options="chartPieOptions.chart" />
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6" align="left"  >
            <div class="padding-mobile-display" style="overflow-y: auto;">
              <v-data-table :headers="piechartexpenseManagementTable_headers" :items="manageExpenseTypeSummary"
                class="elevation-0 packhai-border-table " hide-default-footer :items-per-page="10000"
                mobile-breakpoint="0">
                <template v-slot:item.percentcal="{ item }">
                  {{ get_percent_manage_expense(item.amountBeforeVat) }} %
                </template>
                <template v-slot:item.amountBeforeVat="{ item }">
                  {{ format_price(item.amountBeforeVat) }}
                </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>


    <v-card class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span
          class="ml-3">{{ use_language.proportion_of_expense_profit }}</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">
        <v-row justify="start" align="center" class="my-7 margin-card-status">
          <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartTotalSummary.length > 0">

            <GChart type="PieChart" :settings="{ packages:['corechart'] }" :data="chartTotalSummary"
              :options="chartPieOptions.chart" />
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartTotalSummary.length > 0">
            <div class="padding-mobile-display" style="overflow-y: auto;">
              <v-data-table :headers="piechartexpensePerProfitTable_headers" :items="expensePerProfit"
                class="elevation-0 packhai-border-table " hide-default-footer :items-per-page="10000"
                mobile-breakpoint="0">
               
                <template v-slot:item.amount="{ item }">
                  {{ format_price(item.amount) }}
                </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import { GChart } from 'vue-google-charts'
import { orderService_dotnet } from '@/website/global'
import { format_number, format_order_id, format_price, get_languages } from '@/website/global_function'
import { header_token } from '@/website/backend/token'


export default {
  components: {
    Loading,
    GChart
  },

  data: () => ({
    page_loading: true,
    loading: false,
    loading_btn: false,

    use_language: null,
    set_language: null,

    //search
    search_menu_date_from: false,
    search_date_from: null,
    search_menu_date_to: false,
    search_date_to: null,

    profitReportTable_headers: [
      { text: 'ลำดับ', align: 'center', value: 'no', width: '10px', sortable: false },
      { text: 'OrderID', align: 'center', value: 'id', width: '90px', sortable: false },
      { text: 'ชื่อผู้รับ', align: 'left', value: 'customerName', width: '250px', sortable: false },
      { text: 'เวลาที่สร้าง', align: 'center', value: 'createdDatetime', width: '100px', sortable: false },
      { text: 'เวลาที่ส่งให้ขนส่ง', align: 'center', value: 'sendToExpressDateTime', width: '100px', sortable: false },
      { text: 'ยอดขาย', align: 'right', value: 'orderTotalAmount', width: '100px', sortable: false },
      { text: 'ราคาต้นทุนสินค้า', align: 'right', value: 'productCostPrice', width: '100px', sortable: false },
      { text: 'ค่าใช้จ่ายอื่นๆ', align: 'right', value: 'totalExpenseCost', width: '100px', sortable: false },
      { text: 'กำไร', align: 'right', value: 'profit', width: '100px', sortable: false },
    ],
    expenseReportTable_headers: [
      { text: 'ลำดับ', align: 'center', value: 'num',  width: '50px', sortable: false },
      { text: 'เลขบิล', align: 'center', value: 'idText',width: '90px', sortable: false },
      { text: 'วันที่ออกใบแจ้งหนี้', align: 'center', value: 'invoiceDate',width: '150px', sortable: false },
      { text: 'ประเภทค่าใช้จ่าย', align: 'left', value: 'expenseType',width: '250px', sortable: false },
      { text: 'หมายเลขอ้างอิง', align: 'left', value: 'referenceNumber',  sortable: false },
      { text: 'หมายเหตุ', align: 'left', value: 'remark',   sortable: false },
      { text: 'ยอดเงิน', align: 'right', value: 'amountBeforeVat',width: '100px', sortable: false },
    ],
    piechartexpenseTable_headers: [
      { text: 'ประเภทค่าใช้จ่าย', align: 'left', value: 'expenseType', width: '33%', sortable: false },
      { text: 'ยอดเงิน', align: 'center', value: 'amountBeforeVat', width: '33%', sortable: false },
      { text: 'เปอร์เซ็นต์', align: 'center', value: 'percentcal', width: '33%', sortable: false },
    ],
    piechartexpensePerProfitTable_headers: [
      { text: 'รายละเอียด', align: 'left', value: 'topic', width: '33%', sortable: false },
      { text: 'ยอดเงิน', align: 'center', value: 'amount', width: '33%', sortable: false },
      { text: 'เปอร์เซ็นต์', align: 'center', value: 'percent', width: '33%', sortable: false },
    ],

    expenseManagementReportTable_headers: [
      { text: 'ลำดับ', align: 'center', value: 'num',  width: '50px', sortable: false },
      { text: 'เลขบิล', align: 'center', value: 'idText',width: '90px', sortable: false },
      { text: 'วันที่ออกใบแจ้งหนี้', align: 'center', value: 'invoiceDate',width: '150px', sortable: false },
      { text: 'ประเภทค่าใช้จ่าย', align: 'left', value: 'expenseType',width: '250px', sortable: false },
      { text: 'หมายเลขอ้างอิง', align: 'left', value: 'referenceNumber',  sortable: false },
      { text: 'หมายเหตุ', align: 'left', value: 'remark',   sortable: false },
      { text: 'ยอดเงิน', align: 'right', value: 'amountBeforeVat',width: '100px', sortable: false },
    ],
    piechartexpenseManagementTable_headers: [
      { text: 'ประเภทค่าใช้จ่าย', align: 'left', value: 'expenseType', width: '33%', sortable: false },
      { text: 'ยอดเงิน', align: 'center', value: 'amountBeforeVat', width: '33%', sortable: false },
      { text: 'เปอร์เซ็นต์', align: 'center', value: 'percentcal', width: '33%', sortable: false },
    ],



    profitReportTable_list: [],
    profitTotal: [],
    profitLast: 0,
    expenseItems: [],
    expenseTypeSummary: [],

    manageExpenseItems: [],
    manageExpenseTypeSummary: [],


    TotalManagementExpense: 0,
    chartExpenseTypeSummary: [],
    chartManageExpenseTypeSummary: [],
    chartTotalSummary: [],
    expensePerProfit: [],
    sumExpensePerProfit: 0,
    chartPieOptions: {
      chart: {
        // title: "ยอดขายตามช่องทางการขาย",
        pieHole: 10.4,
        width: '100%',
        height: 450,
        legend: { position: 'bottom' }
      }
    },

  }),

  computed: {
    computed_search_date_from() {
      return this.formatDates(this.search_date_from)
    },

    computed_search_date_to() {
      return this.formatDates(this.search_date_to)
    },
  },

  async created() {

    //เก็บภาษามาไว้ใน use_language
    this.use_language = await this.get_languages(localStorage.getItem("set_language"));
    this.set_date()
    await this.get_data_profit()
    await this.get_data_expense()
    this.page_loading = false
  },

  methods: {
    format_number,
    format_order_id,
    format_price,
    get_languages,

    allowedDates: val => val <= new Date().toISOString().substr(0, 10),

    formatDates(date) {
      if (date == null) {
        return null
      } else {
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      }
    },

    set_format_date(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10)
        const date_split = date_tmp.split('-');
        var time_tmp = val.substring(11, 19)
        var set_dattime = date_split[2] + '-' + date_split[1] + '-' + date_split[0]
        return set_dattime
      } else {
        return ""
      }
    },

    get_percent_expense(val) {
      if (val != null) {
        console.log(val);
        console.log(val);
        var percent = (val / (this.profitTotal.totalExpenseCost*-1)) * 100
        return percent.toFixed(2)
      } else {
        return ""
      }
    },
    get_percent_manage_expense(val) {
      if (val != null) {
        var percent = (val / this.TotalManagementExpense) * 100
        return percent.toFixed(2)
      } else {
        return ""
      }
    },


    set_format_date_time(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10)
        const date_split = date_tmp.split('-');
        var time_tmp = val.substring(11, 19)
        var set_dattime = date_split[2] + '-' + date_split[1] + '-' + date_split[0] + ' ' + time_tmp
        return set_dattime
      } else {
        return ""
      }
    },

    set_zero_date(num) {
      var num_tmp
      if (parseInt(num) >= 10) {
        num_tmp = num
      } else {
        num_tmp = '0' + num
      }
      return num_tmp
    },

    set_date() {
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      var firstDay = year + "-" + this.set_zero_date(month) + '-01'
      var nowdate = year + "-" + this.set_zero_date(month) + "-" + this.set_zero_date(day)

      this.search_date_from = firstDay
      this.search_date_to = nowdate
    },

    detail_page(id) {
      window.open('profit-detail?id=' + id, '_blank');
    },
    detail_po(id) {
      window.open('addExpense?id=' + id, '_blank');
    },

    async get_data_profit() {

      try {
        this.loading_btn = true

        let response = await axios.post(orderService_dotnet + 'Order/get-profit-report', {
          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to,
          "shopID": localStorage.getItem("shop_id"),
        }, { headers: header_token })
        this.profitReportTable_list = []
        this.profitTotal = []

        if (response.data.items.length != 0) {

          this.profitTotal = response.data.items[response.data.items.length - 1]
          response.data.items = response.data.items.slice(0, -1);
          this.profitReportTable_list = response.data.items


        }
        else{
          this.profitTotal = {
            orderTotalAmount: 0,
            productCostPrice: 0,
            profit:0,
            totalExpenseCost: 0
          }
        }


        this.chartTotalSummary.push(["หัวข้อ", "ยอดเงิน"])
        this.chartTotalSummary.push(["ต้นทุนสินค้า", Math.abs(this.profitTotal["productCostPrice"])])
        this.chartTotalSummary.push(["ค่าใช้จ่ายในการขาย", Math.abs(this.profitTotal["totalExpenseCost"])])
        this.expensePerProfit.push({ "topic": "ต้นทุนสินค้า", "amount": Math.abs(this.profitTotal["productCostPrice"]) },)
        this.expensePerProfit.push({ "topic": "ค่าใช้จ่ายในการขาย", "amount": Math.abs(this.profitTotal["totalExpenseCost"]) },)
        this.sumExpensePerProfit = 0
        this.sumExpensePerProfit = this.expensePerProfit.reduce((sum, item) => sum + item.amount, 0);

        this.loading_btn = false
      } catch (e) {
     
        this.$swal({
          type: 'warning',
          title: 'เกิดข้อผิดพลาดบางอย่าง กรุณาติดต่อฝ่าย IT !!',
          text: e,
        })
        this.loading_btn = false
      }
    },
    async get_data_expense() {

      try {
        this.loading_btn = true

        let response = await axios.post(orderService_dotnet + 'PO/get-po-summary', {
          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to,
          "shopID": localStorage.getItem("shop_id"),
        }, { headers: header_token })

        this.expenseItems = []
        this.expenseTypeSummary = []
        this.manageExpenseItems = []
        this.manageExpenseTypeSummary = []

        this.TotalManagementExpense = 0
        this.profitLast = 0

        this.expenseItems = response.data.saleExpenseItem
        this.expenseTypeSummary = response.data.saleExpenseTypeSummary

        this.manageExpenseItems = response.data.managementExpenseItem
        this.manageExpenseTypeSummary = response.data.managementExpenseTypeSummary

        this.TotalManagementExpense = response.data.managementExpenseTypeSummary.reduce((sum, item) => sum + item.amountBeforeVat, 0);
        this.chartExpenseTypeSummary = this.expenseTypeSummary.map(item => ([
          item.expenseType,
          item.amountBeforeVat
        ]));
        this.chartExpenseTypeSummary.unshift(["ค่าใช้จ่ายในการขาย", "ยอดใช้จ่าย"])

        this.chartManageExpenseTypeSummary = this.manageExpenseTypeSummary.map(item => ([
          item.expenseType,
          item.amountBeforeVat
        ]));
        this.chartManageExpenseTypeSummary.unshift(["ค่าใช้จ่ายในการบริหาร", "ยอดใช้จ่าย"])



        this.profitLast = this.profitTotal["profit"] - this.TotalManagementExpense
       
        this.chartTotalSummary.push(["ค่าใช้จ่ายในการบริหาร", this.TotalManagementExpense])
        if (this.profitLast > 0) {
          this.chartTotalSummary.push(["กำไรสุทธิ", this.profitLast])
        }

        this.expensePerProfit.push({ "topic": "ค่าใช้จ่ายในการบริหาร", "amount": this.TotalManagementExpense },)

        if (this.profitTotal.orderTotalAmount > 0) {


          if (this.profitLast >= 0) {
            this.expensePerProfit.push({ "topic": "กำไรสุทธิ", "amount": this.profitLast });
            this.expensePerProfit.forEach(element => {
              var percent = (element.amount / this.profitTotal.orderTotalAmount) * 100
              element.percent = percent.toFixed(2)
            });
          } else {

            var sumTotalCost = 0
            sumTotalCost = this.expensePerProfit.reduce((sum, item) => sum + item.amount, 0);
            var percent = this.profitLast / sumTotalCost * 100;
            this.expensePerProfit.push({ "topic": "กำไรสุทธิ", "amount": this.profitLast, "percent": percent.toFixed(2) });

          }
        }
        else {
          this.expensePerProfit.push({ "topic": "กำไรสุทธิ", "amount": this.profitLast });
          this.expensePerProfit.forEach(element => {
            element.percent = ""
          });

        }


        this.loading_btn = false
      } catch (e) {
       
      
        this.$swal({
          type: 'warning',
          title: 'เกิดข้อผิดพลาดบางอย่าง กรุณาติดต่อฝ่าย IT55 !!',
          text: e,
        })
        this.loading_btn = false
      }
    },



    async search_data_profit() {
      this.chartTotalSummary = []
      this.expensePerProfit = []
      await this.get_data_profit()
      await this.get_data_expense() 
      
    },
  }
}
</script>
<style scoped>
/* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

>>>.bex48-logo {
  width: 12.533vw;
  height: 11.2vw;
  display: block;
  /* margin: 8.8vw auto 1.6vw; */
  margin: 1.6vw auto 1.6vw;
}

>>>.slide-fade-enter-active {
  transition: all .3s ease;
}

>>>.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

>>>.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}

.packhai-border-table {

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.packhai-border-table thead {
  color: #f4f6ff;
}

.packhai-border-table thead tr th {
  font-weight: bold;
  font-size: 2rem;
}

.return-order-table {
  width: 100%;
}

.padding-for-order-dialog {
  padding: 0px !important;
}

>>>.margin-card-r-10 {
  margin-right: 0px;
}

>>>.margin-card-l-10 {
  margin-left: 0px;
}

@media screen and (min-width: 768px) {
  .return-order-table {
    width: 300px;
  }

  .padding-for-order-dialog {
    padding: 15px !important;
  }

  .margin-card-r-3 {
    margin-right: 10px;
  }

  .margin-card-l-3 {
    margin-left: 10px;
  }
}

.packhai-border-preview {
  border-top: solid 1px black;
  border-left: solid 1px black;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  border-radius: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.text-green {
  color: green;
  font-weight: bold;
}

.text-red {
  color: red;
  font-weight: bold;
}

.text-orange {
  color: #ff8d22;
  font-weight: bold;
}

.text-gray {
  color: #7c0e77;
  font-weight: bold;
}

.custom-title {
  padding: 5 !important;
}
</style>
